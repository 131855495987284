ul, #myUL {
  list-style-type: none;
}

#myUL {
  margin: 0;
  padding: 0;
}

.caret {
  cursor: pointer;
  user-select: none;
}

.caret:before {
  content: "▶";
  color: #000;
  margin-right: 6px;
  display: inline-block;
}

.caret-down:before {
  transform: rotate(90deg);
}

.nested {
  display: none;
}

.active {
  display: block;
}

:root {
  --edrp-background-color: #fff;
  --edrp-font-color: #000c;
  --edrp-primary-color: #528fff;
  --edrp-primary-color-alpha: #528fff40;
  --edrp-border-color: lightgrey;
}

.EDRP {
  position: relative;
}

.EDRP * {
  box-sizing: border-box;
}

.EDRP__body {
  color: var(--edrp-font-color);
  background: #fff;
  background: var(--edrp-background-color);
  border: 1px solid var(--edrp-border-color);
  z-index: 1;
  border-radius: 5px;
  position: absolute;
  top: 100%;
}

.EDRP__body--sticky {
  width: 670px;
  position: relative;
}

.EDRP__selectors {
  display: flex;
}

.EDRP__button {
  text-transform: uppercase;
  color: var(--edrp-font-color);
  background: #fff;
  background: var(--edrp-background-color);
  cursor: pointer;
  border: 0;
  border-radius: 3px;
  margin-left: 5px;
  padding: 5px 10px;
  font-family: inherit;
  font-size: .8em;
  font-weight: bold;
}

.EDRP__button--primary {
  background: #528fff;
  background: var(--edrp-primary-color);
  color: #fff;
}

.EDRP__button[disabled] {
  opacity: .4;
}

.EDRPPresets {
  display: flex;
}

.EDRPPresets__list {
  flex: 1;
  min-width: 120px;
  max-height: 226px;
  margin: 0;
  padding: 15px 10px;
  list-style: none;
  overflow: auto;
}

.EDRPPresets__label {
  cursor: pointer;
  border-radius: 3px;
  padding: 5px 10px;
}

.EDRPPresets__label:hover {
  background: #9b9b9b4d;
}

.EDRPPresets__label--active {
  background: #528fff;
  background: var(--edrp-primary-color);
  color: #fff;
}

.EDRPPresets__label--active:hover {
  background: #528fff;
  background: var(--edrp-primary-color);
}

.EDRPCalendar {
  text-align: center;
  margin: 0 10px 10px 0;
  padding: 10px 0 0 10px;
}

.EDRPCalendar__cell {
  cursor: pointer;
  user-select: none;
  border-radius: 3px;
  padding: 7px 9px;
  font-size: .9em;
  font-weight: bold;
}

.EDRPCalendar__cell:hover {
  background: #9b9b9b4d;
}

.EDRPCalendar__cell--active {
  background: #528fff;
  background: var(--edrp-primary-color);
  color: #fff;
}

.EDRPCalendar__cell--disabled {
  opacity: .5;
  text-decoration: line-through;
}

.EDRPCalendar__cell--inRange {
  background: #528fff40;
  background: var(--edrp-primary-color-alpha);
  border-radius: 0;
}

.EDRPCalendar__cell--inRange:hover {
  background: #528fff40;
  background: var(--edrp-primary-color-alpha);
}

.EDRPCalendar__cell--end {
  background: #528fff;
  background: var(--edrp-primary-color);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.EDRPCalendar__cell--end:hover {
  background: #528fff;
  background: var(--edrp-primary-color);
}

.EDRPCalendar__cell--off {
  opacity: .5;
  font-weight: normal;
}

.EDRPCalendar__cell--start {
  background: #528fff;
  background: var(--edrp-primary-color);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.EDRPCalendar__cell--start:hover {
  background: #528fff;
  background: var(--edrp-primary-color);
}

.EDRPCalendar__cell--today {
  outline: 1px dashed #9b9b9b;
}

.EDRPCalendar__head {
  height: 36px;
}

.EDRPCalendar__nav {
  opacity: .5;
  cursor: pointer;
  user-select: none;
  border-radius: 5px;
  font-size: 1.1em;
}

.EDRPCalendar__nav:hover {
  background: #9b9b9b4d;
}

.EDRPCalendar__table {
  border-spacing: 0;
  border-collapse: collapse;
}

.EDRPFoot {
  background: #9b9b9b33;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  display: flex;
}

.EDRPFoot__actions {
  margin-left: auto;
}

/*# sourceMappingURL=index.8cfe674e.css.map */
